import styled from "@emotion/styled";
import { LOCATIONS_ROUTES, ROUTES_PARAMETERS } from "@components/routing/constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ElasticSearchInput from "@components/ElasticSearchInput";
import { InputBase } from "@mui/material";
import { withQueries } from "optigo-redux/lib";

const ElasticSearchULResult = styled.ul`
  position: absolute;
  z-index: 15;
  width: 412px;
  background: #fff;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
  box-shadow: 0 3px 4px -1px #9a9a9a;

  &.height-auto {
    height: auto;
    overflow-y: hidden;
  }

  &.height-limit {
    height: 500px;
    overflow-y: scroll;
  }

  li {
    transition: 0.2s ease;

    a {
      display: block;
      padding: 16px 8px;
      text-decoration: none;
      color: #424242;
      transition: 0.2s ease;
    }

    &:hover {
      background: #f4f4f4;

      a {
        color: #006f9c;
      }
    }

    &:not(:first-of-type) {
      border-top: 1px solid #ebebeb;
    }
  }
`;

const buildResultHTML = (result, setHtmlResult) => {
  const listItems = result.map(({ _id: itemId, _index: itemIndex, _source: itemSource }) => {
    if (itemIndex === "customers") {
      return (
        <li key={itemId}>
          <a
            href={`/customers/${itemSource.id}`}
            className="elastic-search-link"
          >
            Client : {itemSource.name}
          </a>
        </li>
      );
    }

    if (itemIndex === "locations") {
      if (!itemSource.locatable) return null;

      const { address } = itemSource;
      let addressForHtml = "";
      if (itemSource.address) {
        addressForHtml = `${address && address.door_no ? address.door_no : ""}
          ${address && address.adr_1 ? address.adr_1 : ""} ${
            address && address.postal_code ? address.postal_code : ""
          } ${address && address.city ? address.city : " "}`;
      }
      if (itemSource.geoposition) {
        addressForHtml = `${itemSource.geoposition.description}`;
      }

      const pathToLocation =
        itemSource.contract_locations.length === 0
          ? LOCATIONS_ROUTES.location.replace(ROUTES_PARAMETERS.locationId, itemSource.id)
          : `/customers/${itemSource.locatable.id}/contracts/${itemSource.contract_locations[0].contract_id}/locations/${itemSource.contract_locations[0].location_id}`;
      return (
        <li key={itemId}>
          <a
            href={pathToLocation}
            className="elastic-search-link"
          >
            Emplacement : {itemSource.name} - Adresse : {addressForHtml} - # de dossier :{" "}
            {itemSource.file_number ? itemSource.file_number : "N/A"} - Client : {itemSource.locatable.name}
          </a>
        </li>
      );
    }

    if (itemIndex === "addresses") {
      const { addressable, adr_1, door_no, city, postal_code } = itemSource;

      if (!addressable || !addressable.contract_locations?.length) return null;

      const { contract_id, location_id } = addressable.contract_locations[0];
      return (
        <li key={itemId}>
          <a
            href={`/customers/${addressable.locatable_id}/contracts/${contract_id}/locations/${location_id}`}
            className="elastic-search-link"
          >
            Emplacement : {addressable.name} - Adresse : {door_no} {adr_1} {postal_code} {city} - # de dossier :{" "}
            {addressable.file_number || "-"}
          </a>
        </li>
      );
    }

    if (itemIndex === "geopositions") {
      const { location_id, description, location } = itemSource;
      const { locatable_id, name, file_number, contract_locations } = location || {};

      if (!contract_locations?.length) return null;

      return (
        <li key={itemId}>
          <a
            href={`/customers/${locatable_id}/contracts/${contract_locations[0].contract_id}/locations/${location_id}`}
            className="elastic-search-link"
          >
            Emplacement : {name} - Adresse : {description} - # de dossier : {file_number || "-"}
          </a>
        </li>
      );
    }
    return null;
  });

  setHtmlResult(listItems);
};

const HeaderSearchBar = (props) => {
  const { elasticQueries } = props;
  const [keyword, setKeyword] = useState("");
  const [htmlResult, setHtmlResult] = useState(0);

  const handleChangeSearch = useCallback(async (event) => {
    setKeyword(event.target.value);

    const result = await elasticQueries(event.target.value.replace(/%/g, ""));

    buildResultHTML(result, setHtmlResult);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (
      !event.target.classList.contains("elastic-search-input") &&
      !event.target.classList.contains("elastic-search-link")
    ) {
      setHtmlResult(null);
      setKeyword("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when unmounting
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <ElasticSearchInput>
      <InputBase
        style={{ borderRadius: "5px" }}
        value={keyword}
        onChange={handleChangeSearch}
        type="text"
        inputProps={{
          "aria-label": "search",
          className: "elastic-search-input",
          id: "elastic-search-input",
        }}
        variant="outlined"
        className="elastic-search-input-wrap"
        id="elastic-search-input-wrap"
      />
      {htmlResult !== null && htmlResult.length > 0 && (
        <ElasticSearchULResult
          className={htmlResult.length > 2 ? "height-limit" : "height-auto"}
          id="searchResultDisplay"
        >
          {htmlResult}
        </ElasticSearchULResult>
      )}
    </ElasticSearchInput>
  );
};

export default withQueries(HeaderSearchBar);
