import { Button, DialogActions, DialogContent, DialogTitle, FormHelperText, MenuItem } from "@mui/material";
import moment from "moment";
import {
  withCustomerItem,
  withCustomerLocations,
  withJobTemplates,
  withRoutes,
  withRouteTemplates,
  withSupplierLocations,
} from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";
import styled from "@emotion/styled";
import { orderBy } from "lodash";

import Autocomplete from "./ui/Autocomplete";
import DatePicker from "./form/DatePickerMui";
import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import SelectUi from "./ui/Select";
import SelectHalfUi from "./ui/SelectHalf";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { formattedDate } from "@utils/dates";
import TextAreaUi from "./ui/TextArea";
import { filterSupplierLocations } from "@utils/filtering";
import LoaderSpinner from "@components/LoaderSpinner";
import * as API from "@services";
import createLineaCollection from "@services/collection-sectors/create-linea-collection.service";

const initialState = {
  errors: {
    averageLiftingTime: false,
    customerDestinationLocationId: false,
    customerLocationId: false,
    depositId: false,
    jobTemplateId: false,
    name: false,
    routeTemplateId: false,
    startDate: false,
  },
  averageLiftingTime: "",
  customerDestinationLocationId: "-1",
  customerLocationId: "-1",
  depositId: "-1",
  jobTemplateId: "-1",
  locationModalOpened: false,
  name: "",
  noteComments: "",
  routeTemplateId: "-1",
  startDate: formattedDate(),
  supplierLocationId: "-1",
  showSpinner: false,
  unitId: "-1",
  routeFrequencyWeeklyChecked: true,
  routeFrequencyTwiceMonthlyChecked: true,
  routeFrequencyMonthlyChecked: true,
  units: [],
};

const LiftingFrequencyWrapper = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.basic.lightGrey};
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  padding: ${({ theme }) => theme.inputPadding};
  padding-left: ${({ adornment, theme }) => (adornment === "start" ? "40px" : theme.inputPadding)};
  padding-right: ${({ adornment, theme }) => (adornment === "end" ? "40px" : theme.inputPadding)};
  display: flex;
  align-items: space-between;
  flex-direction: row;
  position: relative;
  padding: 20px;

  .color-picker-label {
    position: absolute;
    top: -6px;
    left: 6px;
    background: #fff;
    padding: 0 5px;
    font-size: 12px;
  }
`;

class ModalRoute extends PureComponent {
  state = {
    ...initialState,
  };

  async componentDidMount() {
    const { router } = this.props;
    const { customerItemId } = router.params;
    this.props.fetchRouteTemplatesByCustomerItem(customerItemId, {
      filter: "",
      limit: 250,
      page: 1,
    });

    await this.fetchAllSuppliersLocations();
    await this.fetchUnits();
  }

  get valid() {
    const errors = { ...initialState.errors };
    const { averageLiftingTime, jobTemplateId, routeTemplateId, startDate } = this.state;
    const routeTemplate = this.getRouteTemplate(routeTemplateId);
    let valid = true;

    // for (const name of ["name"]) {
    //   if (this.state[name].toString().trim() === "" || this.state[name].toString().trim() === "-1") {
    //     valid = false;
    //     errors[name] = true;
    //   }
    // }

    if (routeTemplateId === "-1" && jobTemplateId === "-1") {
      valid = false;
      errors.jobTemplateId = true;
    }

    if (routeTemplateId === "-1" && averageLiftingTime.toString().trim() === "") {
      valid = false;
      errors.averageLiftingTime = true;
    }

    const { routeTemplateIsAssignedToDate } = routeTemplate;

    if (routeTemplateIsAssignedToDate.includes(startDate)) {
      valid = false;
      errors.startDate = true;
    }

    if (!startDate) {
      valid = false;
      errors.startDate = true;
    }

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  // eslint-disable-next-line max-len
  getJobTemplate = (jobTemplateId) => this.props.jobTemplates.find(({ id }) => id === jobTemplateId.toString()) || {};

  // eslint-disable-next-line max-len
  getRouteTemplate = (routeTemplateId) =>
    this.props.routeTemplates.flatMap((rt) => rt.children).find(({ id }) => id === routeTemplateId) || {};

  dateWhereRouteTemplateIsAlreadyAssigned = (date, routeTemplateIsAssignedToDate) => {
    if (routeTemplateIsAssignedToDate.includes(date.format("YYYY-MM-DD"))) {
      return true;
    }
  };

  fetchAllSuppliersLocations = async () => {
    await this.props.fetchAllSuppliersLocations();
  };

  fetchUnits = async () => {
    const units = await API.Unit.fetchUnits({ rowsPerPage: "all" });

    this.setState({ units: units?.instances ?? [] });
  };

  createCollection = async (payload) => {
    await createLineaCollection(payload);
  };

  handleChangeFields = handleChangeFields.bind(this);

  handleChangeJobTemplate = ({ target }) => {
    const { value } = target;

    const newState = {
      errors: {
        ...initialState.errors,
        jobTemplateId: false,
      },
      jobTemplateId: value,
    };

    const jobTemplate = this.getJobTemplate(value);
    const { contractMatterSpec } = this.props.customerItem;
    const { supplierLocationId } = this.state;

    if (value === "-1" || (jobTemplate && jobTemplate.code && jobTemplate.code.toUpperCase().indexOf("LI") > -1)) {
      newState.supplierLocationId = "-1";
    } else if ((supplierLocationId === "-1" || !supplierLocationId) && contractMatterSpec) {
      const { defaultSupplierLocationId } = contractMatterSpec[0];

      if (defaultSupplierLocationId) {
        newState.supplierLocationId = defaultSupplierLocationId;
      }
    }

    this.setState(newState);
  };

  handleChangeStartDate = (date) => {
    this.setState({
      errors: {
        ...this.state.errors,
        startDate: false,
      },
      startDate: formattedDate(date),
    });
  };

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  handleChangeRouteTemplateId = (name) => (target) => {
    const newState = {
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      selectedRouteTemplate: target,
      [name]: target.value,
    };

    const routeTemplate = this.getRouteTemplate(target.value);
    newState.name = routeTemplate.name;
    this.setState(newState);
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { createRoute, refreshList } = this.props;

      const { router, customerItem } = this.props;
      const { customerItemId } = router.params;
      const { contractType } = customerItem;

      this.setState({ showSpinner: true });

      const {
        averageLiftingTime,
        jobTemplateId,
        name,
        noteComments,
        routeTemplateId,
        startDate,
        supplierLocationId,
        unitId,
        routeFrequencyWeeklyChecked,
        routeFrequencyMonthlyChecked,
        routeFrequencyTwiceMonthlyChecked,
      } = this.state;

      await createRoute({
        average_lifting_time: averageLiftingTime,
        customer_item_id: customerItemId,
        job_template_id: jobTemplateId,
        name,
        note_comments: noteComments.trim(),
        route_template_id: routeTemplateId,
        start_date: startDate,
        supplier_location_id: supplierLocationId,
        unit_id: unitId,
        weekly: routeFrequencyWeeklyChecked,
        monthly: routeFrequencyMonthlyChecked,
        twice_monthly: routeFrequencyTwiceMonthlyChecked,
      });

      if (contractType === "collection") {
        await this.createCollection({
          contractId: router.params.contractId,
          unitId,
          date: startDate,
          collectionSectorId: routeTemplateId,
          supplierLocationId,
        });
      }

      this.setState(initialState);

      refreshList();
    }
  };

  toggleFrequencyCheckbox = (frequency) => (event) => {
    const stateVariable = `routeFrequency${frequency}Checked`;
    this.setState({ [stateVariable]: !this.state[stateVariable] });
  };

  renderLocationsFields = () => {
    const { customerDestinationLocationId, errors, jobTemplateId, routeTemplateId, supplierLocationId } = this.state;

    const { customerLocations, supplierLocations } = this.props;

    const jobTemplate = this.getJobTemplate(jobTemplateId);

    const routeTemplate = this.getRouteTemplate(routeTemplateId);

    if (jobTemplate.code === "RE") {
      return (
        <SelectUi
          disabled={
            routeTemplateId !== "-1" ||
            jobTemplateId === "-1" ||
            (jobTemplate && jobTemplate.code && jobTemplate.code.toUpperCase().indexOf("LI") > -1)
          }
          error={errors.customerDestinationLocationId}
          formControlError={errors.customerDestinationLocationId}
          formHelperErrorMsg={this.getErrorMessage("customerDestinationLocationId")}
          id="cpbr-customer-destination-location"
          inputLabelText={<Trans i18nKey="customer_destination_location" />}
          onChange={this.handleChangeFields("customerDestinationLocationId")}
          value={
            routeTemplateId === "-1"
              ? `${customerDestinationLocationId}`
              : `${routeTemplate.customerDestinationLocationId}`
          }
        >
          {this.renderMenuItems(<Trans i18nKey="select_customer_destination_location" />, customerLocations, "name")}
        </SelectUi>
      );
    }

    const filteredSupplierLocations = filterSupplierLocations(supplierLocations, jobTemplate.code);

    return (
      <SelectUi
        disabled={false}
        error={errors.supplierLocationId}
        formControlError={errors.supplierLocationId}
        formHelperErrorMsg={this.getErrorMessage("supplierLocation")}
        id="cpbr-supplier-location"
        inputLabelText={<Trans i18nKey="drop_point_1" />}
        onChange={this.handleChangeFields("supplierLocationId")}
        value={supplierLocationId}
      >
        {this.renderMenuItems(<Trans i18nKey="select_drop_point" />, filteredSupplierLocations, "name")}
      </SelectUi>
    );
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderAutocompleteList = (data) => {
    if (data.length > 0) {
      return orderBy(data.map(({ id, completeName }) => ({ value: id, label: completeName })));
    }
    return [{ value: "", label: "Aucun modèle de route trouvé" }];
  };

  renderLocation = () => {
    const { errors, name } = this.state;
    return (
      <FormGroupWrapper>
        <TextFieldUi
          error={errors.name}
          helperText={this.getErrorMessage("name")}
          id="cpbr-name"
          label={<Trans i18nKey="routes.name" />}
          onChange={this.handleChangeFields("name")}
          value={name}
        />
      </FormGroupWrapper>
    );
  };

  renderDateAndUnit = () => {
    const { errors, noteComments, selectedRouteTemplate, startDate, unitId, units } = this.state;
    const { routeTemplates, customerItem } = this.props;

    const routeTemplate = routeTemplates
      .flatMap((rt) => rt?.children)
      .find((routeTemp) => routeTemp.id === selectedRouteTemplate.value);

    const { routeTemplateIsAssignedToDate } = routeTemplate;

    const loadingType = customerItem.loadingType === "CCAV" ? "AVANT" : "LATERAL";

    const availableSortedUnit = orderBy(units, ["name", "id"], ["asc", "asc"]).filter(
      (unit) => unit.loadingType === loadingType
    );
    return (
      <FormGroupWrapper>
        <FlexRowWrapper>
          <HalfFormControl error={errors.startDate}>
            <DatePicker
              error={errors.startDate}
              label="Date"
              value={moment(startDate)}
              onChange={this.handleChangeStartDate}
              variant="outlined"
              disablePast={false}
              shouldDisableDate={(date) =>
                this.dateWhereRouteTemplateIsAlreadyAssigned(date, routeTemplateIsAssignedToDate)
              }
            />
            {this.state.errors.startDate && (
              <FormHelperText>
                {this.getErrorMessage("startDate", "required", "routes.route_already_assigned_to_date")}
              </FormHelperText>
            )}
          </HalfFormControl>
          <SelectHalfUi
            id="cpbr-unit"
            inputLabelText={<Trans i18nKey="unit" />}
            onChange={this.handleChangeFields("unitId")}
            value={`${unitId}`}
          >
            {this.renderMenuItems(<Trans i18nKey="select_unit" />, availableSortedUnit, "name")}
          </SelectHalfUi>
        </FlexRowWrapper>
      </FormGroupWrapper>
    );
  };

  renderNoteRoute = () => {
    const { errors, noteComments } = this.state;
    return (
      <FormGroupWrapper>
        <TextAreaUi
          error={errors.note_comments}
          helperText={this.getErrorMessage("note_comments")}
          id="cpbr-note-comments"
          label={<Trans i18nKey="preparation.note_route" />}
          onChange={this.handleChangeFields("noteComments")}
          value={noteComments || ""}
        />
      </FormGroupWrapper>
    );
  };

  render() {
    const { errors, selectedRouteTemplate, showSpinner } = this.state;
    const { open, routeTemplates } = this.props;

    const subRoutes = routeTemplates.flatMap((routeTemplate) => routeTemplate.children);

    return (
      <DialogWrapper
        id="route-modal"
        onClose={this.handleClose}
        open={open}
      >
        {showSpinner && <LoaderSpinner text={<Trans i18nKey="routes.route_creation" />} />}
        <DialogTitle>
          <Trans i18nKey="add_route" />
        </DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <Autocomplete
                error={errors.routeTemplateId}
                formControlError={errors.routeTemplateId}
                formHelperErrorMsg={this.getErrorMessage("routeTemplateId")}
                id="cpbr-route-template"
                onChange={this.handleChangeRouteTemplateId("routeTemplateId")}
                value={selectedRouteTemplate}
                placeholder={<Trans i18nKey="select_route_template_id" />}
                dropDownMenuPortalTarget="#route-modal"
                options={this.renderAutocompleteList(subRoutes)}
              />
            </FormGroupWrapper>

            {/* <FormGroupWrapper>
              <LiftingFrequencyWrapper autoFocus>
                <InputLabel className="color-picker-label"><Trans
                  i18nKey="frequency_of_lifting_to_import"
                />
                </InputLabel>
                <FormControlLabel
                  control={<Checkbox checked={this.state.routeFrequencyWeeklyChecked} />}
                  label={<Trans i18nKey="frequencies.weekly" />}
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.routeFrequencyTwiceMonthlyChecked} />}
                  onChange={this.toggleFrequencyCheckbox('TwiceMonthly')}
                  label={<Trans i18nKey="frequencies.twice_monthly" />}
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.routeFrequencyMonthlyChecked} />}
                  onChange={this.toggleFrequencyCheckbox('Monthly')}
                  label={<Trans i18nKey="frequencies.monthly" />}
                />
              </LiftingFrequencyWrapper>
            </FormGroupWrapper> */}

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>{this.renderLocationsFields()}</HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            {/*{selectedRouteTemplate && this.renderLocation()}*/}
            {selectedRouteTemplate && this.renderDateAndUnit()}
            {selectedRouteTemplate && this.renderNoteRoute()}
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button
            onClick={this.handleSubmit}
            variant="contained"
          >
            <Trans i18nKey="add" />
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalRoute.propTypes = {
  createRoute: PropTypes.func.isRequired,
  customerItem: PropTypes.object.isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchRouteTemplatesByCustomerItem: PropTypes.func.isRequired,
  jobTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,

  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  routeTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// eslint-disable-next-line max-len
export default withJobTemplates(
  withCustomerItem(withCustomerLocations(withSupplierLocations(withRoutes(withRouteTemplates(withRouter(ModalRoute))))))
);
