import { axiosClientV1 as http } from "../axios";

const deleteCollection = async (collectionId: number): Promise<void> => {
  try {
    await http.delete(`/collections/${collectionId}`);
  } catch (error) {
    throw error as Error;
  }
};

export default deleteCollection;
