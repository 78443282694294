// React imports
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";

// External imports
import styled from "@emotion/styled";

import {
  Icon,
  Fab,
  Stack,
  Button,
  MenuItem,
  InputBase,
  Typography,
  DialogTitle,
  DialogActions,
  Checkbox,
  ListItemText,
  Dialog,
  DialogContent,
} from "@mui/material";

import MultiSelect from "@khanacademy/react-multi-select";
import LinearProgress from "@mui/material/LinearProgress";
import { Trans } from "react-i18next";
import { DragDropContext } from "react-beautiful-dnd";
import { withJobs, withRoutes, withQueries, withAssignments } from "optigo-redux";
import { filter, orderBy, cloneDeep, uniqBy, map, forEach, reduce, find } from "lodash";
import Select from "@mui/material/Select";

// Local imports
import useToggle from "@hooks/useToggle";
import useAsync from "@hooks/useAsync";
import SelectHalfUi from "@ui/SelectHalf";
import { formattedDate } from "@utils/dates";
import DialogWrapper from "@ui/DialogWrapper";
import { withRouter } from "@utils/withRouter";
import ModalWarning from "@components/ModalWarning";
import UnitRow from "@components/dashboard/UnitRow";
import useLocalStorage from "@hooks/useLocalStorage";
import LoaderSpinner from "@components/LoaderSpinner";
import ActionBar from "@components/interventionsBoard/ActionBar";
import UnassignedCart from "@components/dashboard/UnassignedCart";
import ModalQuickTask from "@components/modals/quickTask/ModalQuickTask";
import LoadingButton from "@ui/LoadingButton";
import { CurrencyExchange } from "@mui/icons-material";
import * as API from "@services";
import RemoveHelpOnUnit from "@components/dashboard/RemoveHelpOnUnit";
import { LOCATIONS_ROUTES, ROUTES_PARAMETERS } from "@components/routing/constants";

const CtaButton = styled(Fab)`
  && {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const DashboardContent = styled.div`
  background-color: rgb(248, 248, 248);
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0 35px;
  overflow: hidden;
  flex: 1;
`;

const DashboardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 2px 5px;
`;

const H1Style = {
  flex: 1,
  fontSize: "1rem",
  marginLeft: "30px",
  marginBottom: "5px",
};

const renderMenuItems = (label, data, key) => [
  <MenuItem
    key="-1"
    value="-1"
  >
    {label}
  </MenuItem>,
  ...data.map(({ id, ...remainingData }) => (
    <MenuItem
      key={id}
      value={id}
    >
      {remainingData[key]}
    </MenuItem>
  )),
];

const createAssignmentsSourceObject = (assignments) => {
  if (assignments.length <= 0) {
    return [];
  }

  return map(assignments, (assignment) => ({
    id: assignment.id,
    display_order: assignment.displayOrder,
    source_unit_id: assignment.unitId,
    destination_unit_id: assignment.unitId,
  }));
};

const createAssignmentsDestinationObject = (
  assignments,
  destinationIndex,
  sourceUnitID,
  isDestinationUnassignedCart
) => {
  return map(assignments, (assignment, index) => {
    const unitID = isDestinationUnassignedCart
      ? sourceUnitID
      : index === destinationIndex
      ? sourceUnitID
      : assignment.unitId;

    return {
      id: assignment.id,
      published: assignment.published,
      display_order: assignment.displayOrder,
      source_unit_id: unitID,
      destination_unit_id: assignment.unitId,
    };
  });
};

/**
 * Updates the displayOrder of the source and destination assignments of a unit. If
 * *destinationAssignments* is provided, also updates the unitID of the dragged assignment
 * with the one of the new destination unit row.
 * @param {Array} sourceAssignments Assignments array of the source unit row
 * @param {Array} destinationAssignments Assignments array of the destination unit row
 * @param {Integer} destinationIndex Index of the destination unit row
 * @param {Integer} destinationUnitID UnitID of the destination unit row
 */
const updateUnitAssignments = (sourceAssignments, destinationAssignments, destinationIndex, destinationUnitID) => {
  // Update the displayOrder of each assignments of the source unit row
  if (sourceAssignments.length > 0) {
    forEach(sourceAssignments, (assignment, index) => (assignment.displayOrder = index));
  }

  // Update the displayOrder of each assignments of the destination unit
  // row to match current index and also update unitID of dragged assignment
  if (destinationAssignments && destinationAssignments.length > 0) {
    forEach(destinationAssignments, (assignment, index) => {
      assignment.displayOrder = index;

      if (index === destinationIndex) {
        assignment.published = false;
        assignment.unitId = destinationUnitID;
      }
    });
  }
};

/**
 * Reorders the dashboard assignments from the unit rows or unassigned cart.
 * @param {Array} unassignedList Array of assignments from the unassigned cart
 * @param {Array} unitsList Array of units
 * @param {Integer} sourceIndex Source index of the dragged assignment
 * @param {Integer} sourceDroppableID Source droppable id of the dragged assignment
 * @param {Integer} destinationIndex Destination index of the dragged assignment
 * @param {Integer} destinationDroppableID Destination droppable id of the dragged assignment
 * @returns
 * - `clonedUnassignedList`: The updated list of unassigned assignments
 * - `clonedUnitsList`: The updated list of units
 * - `assignmentsToUpdate`: An array of all the assignments to update in the database
 */
const reorderAssignments = (
  unassignedList,
  unitsList,
  sourceIndex,
  sourceDroppableID,
  destinationIndex,
  destinationDroppableID
) => {
  // Convenience variables
  const isDestinationUnassignedCart = destinationDroppableID === "-1";
  const isSourceUnassignedCart = sourceDroppableID === "-1";
  const isSourceUnitRow = sourceDroppableID !== "-1";
  const isDestinationUnitRow = destinationDroppableID !== "-1";
  const isUnitRow = isSourceUnitRow && isDestinationUnitRow;

  // Clone the lists since we don't want mutate states
  const clonedUnitsList = cloneDeep(unitsList);
  const clonedUnassignedList = unassignedList.length > 0 ? cloneDeep(unassignedList) : [];

  // Source convenience variables
  const sourceUnit = isSourceUnitRow && clonedUnitsList[sourceDroppableID];
  const sourceDailyAssignments = isSourceUnitRow && sourceUnit.dailyAssignments;

  // Destination convenience variables
  const destinationUnit = isDestinationUnitRow && clonedUnitsList[destinationDroppableID];
  const destinationDailyAssignments = isDestinationUnitRow && destinationUnit.dailyAssignments;

  // Arrays that will contain our assignments to send to the backend for updating database
  let sourceAssignmentsToUpdate = [];
  let destinationAssignmentsToUpdate = [];

  /*
    Each drag-and-drop scenarios will have the following logic applied.

    1. Remove the source assignment from its unit row
    2. Add it to the destination unit row index
    3. Update the assignments display order and unitID
    4. Create the assignments objects array to send to backend for updating database
  */

  // Drag-and-drop an assignment from the same unit row
  if (isUnitRow && sourceDroppableID === destinationDroppableID) {
    const [removedAssignment] = sourceDailyAssignments.splice(sourceIndex, 1);

    destinationDailyAssignments.splice(destinationIndex, 0, removedAssignment);

    updateUnitAssignments(sourceDailyAssignments);

    sourceAssignmentsToUpdate = createAssignmentsSourceObject(sourceDailyAssignments);
  }

  // Drag-and-drop an assignment from one unit row to another
  if (isUnitRow && sourceDroppableID !== destinationDroppableID) {
    const [removedAssignment] = sourceDailyAssignments.splice(sourceIndex, 1);

    destinationDailyAssignments.splice(destinationIndex, 0, removedAssignment);

    updateUnitAssignments(sourceDailyAssignments, destinationDailyAssignments, destinationIndex, destinationUnit.id);

    sourceAssignmentsToUpdate = createAssignmentsSourceObject(sourceDailyAssignments);

    destinationAssignmentsToUpdate = createAssignmentsDestinationObject(
      destinationDailyAssignments,
      destinationIndex,
      sourceUnit.id
    );
  }

  // Drag-and-drop an assignment from a unit row to the unassigned cart
  if (isSourceUnitRow && isDestinationUnassignedCart) {
    const [removedAssignment] = sourceDailyAssignments.splice(sourceIndex, 1);

    clonedUnassignedList.splice(destinationIndex, 0, removedAssignment);

    updateUnitAssignments(sourceDailyAssignments, clonedUnassignedList, destinationIndex, -1);

    sourceAssignmentsToUpdate = createAssignmentsSourceObject(sourceDailyAssignments);
    destinationAssignmentsToUpdate = createAssignmentsDestinationObject(
      [removedAssignment],
      destinationIndex,
      sourceUnit.id,
      true
    );
  }

  // Drag-and-drop an assignment from the unassigned cart to a unit row
  if (isSourceUnassignedCart && isDestinationUnitRow) {
    const [removedAssignment] = clonedUnassignedList.splice(sourceIndex, 1);

    destinationDailyAssignments.splice(destinationIndex, 0, removedAssignment);

    updateUnitAssignments([], destinationDailyAssignments, destinationIndex, destinationUnit.id);

    destinationAssignmentsToUpdate = createAssignmentsDestinationObject(
      destinationDailyAssignments,
      destinationIndex,
      -1,
      false
    );
  }

  const assignmentsToUpdate = [...sourceAssignmentsToUpdate, ...destinationAssignmentsToUpdate];

  return [clonedUnassignedList, clonedUnitsList, assignmentsToUpdate];
};

const fetchAllAssignments = async (
  currentDate,
  fetchDailyUnassignedAssignments,
  setUnits,
  setUnassignedAssignments
) => {
  try {
    const units = await API.Unit.fetchUnitsWithAssignments({ date: currentDate });
    const orderedUnits = orderBy(units, ["name", "asc"]);

    setUnits(orderedUnits);

    const unassignedAssignments = await fetchDailyUnassignedAssignments(currentDate);
    setUnassignedAssignments(unassignedAssignments);
  } catch (err) {
    console.warn("[fetchAllAssignments] error:", err);
  }
};

const unpublishedAssignmentsCount = (units) =>
  reduce(units, (sum, unit) => sum + filter(unit.dailyAssignments, (assignment) => !assignment.published).length, 0);

const LinearProgressBar = () => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <LinearProgress />
    </div>
  );
};

function AssignmentDashboardPage(props) {
  const { publishJobs, publishRoutes, createAnotherAssignmentToRoute, fetchDailyUnassignedAssignments } = props;

  const [units, setUnits] = useState([]);
  const [unitId, setUnitId] = useState(-1);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [routeToDelete, setRouteToDelete] = useState(null);
  const [currentDate, setCurrentDate] = useState(formattedDate());
  const [openUnitSelector, setOpenUnitSelector] = useState(false);
  const [openRemoveHelpOnUnit, setOpenRemoveHelpOnUnit] = useState(false);
  const [addJobModalOpened, setAddJobModalOpened] = useState(false);
  const [currentAssignmentId, setCurrentAssignmentId] = useState("");
  const [unitAlreadyAssigned, setUnitAlreadyAssigned] = useState(-1);
  const [unassignedAssignments, setUnassignedAssignments] = useState([]);
  const [deleteJobModalOpened, setDeleteJobModalOpened] = useState(false);
  const [selectedUnits, setSelectedUnits] = useLocalStorage("dashboardSelectedUnits", []);
  const [selectedUnitsType, setSelectedUnitsType] = useLocalStorage("dashboardSelectedUnitsType", []);
  const [checkedAll, setCheckedAll] = useState([true, false]);
  const [checkedTypesAll, setCheckedTypesAll] = useState([true, false]);
  const {
    state: isModalQuickTaskShowing,
    ref: isQuickTaskShowingRef,
    setState: setIsModalQuickTaskShowing,
  } = useToggle(false);

  const isDragging = useRef(false);
  const today = new Date().toISOString().slice(0, 10);

  const fetchAssignments = useAsync(() =>
    fetchAllAssignments(currentDate, fetchDailyUnassignedAssignments, setUnits, setUnassignedAssignments)
  );

  const handleCloseModalQuickTask = () => {
    fetchAssignments.run();
    setIsModalQuickTaskShowing.off();
  };

  const handleOpenUnitSelector = useCallback((unitId, assignmentId) => {
    assignmentId && setCurrentAssignmentId(assignmentId);
    if (unitId) {
      setOpenUnitSelector(!openUnitSelector);
      setUnitAlreadyAssigned(unitId);
    }
  }, []);

  const handleOpenRemoveHelpOnUnit = useCallback((unitId, assignmentId) => {
    assignmentId && setCurrentAssignmentId(assignmentId);
    if (unitId) {
      handleToggleRemoveHelpOnUnit();
      setUnitId(unitId);
    }
  }, []);

  const filteredUnits = useCallback(
    filter(
      units,
      (unit) => unit && selectedUnits.includes(unit.id) && selectedUnitsType.includes(unit.unitLoadingType)
    ),
    [units, selectedUnits, selectedUnitsType]
  );

  // Used for the selected unit selector
  const unitsList = useMemo(
    () =>
      units.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [units]
  );

  // Used for the selected unit type selector
  const unitsTypesList = useMemo(
    () =>
      map(uniqBy(orderBy(units, ["name", "id"], ["asc", "asc"]), "unitLoadingType"), (e) => ({
        label: e.unitLoadingType,
        value: e.unitLoadingType,
      })),
    [units]
  );

  useEffect(() => {
    let interval;
    fetchAssignments.run();

    // If selected date is today, add interval timer to run fetchAssignments.run every 30 seconds
    if (currentDate === today) {
      interval = setInterval(() => {
        // Make sure user is not dragging an assignment, that there's no ongoing data fetching
        // and that the QuickTask modal is not currently open

        if (isDragging.current === false && !fetchAssignments.loading && isQuickTaskShowingRef.current === false) {
          fetchAssignments.run();
        }
      }, 30000);
    }

    // Remove units, assignments and interval from memory when unmounting, useful when switching dates
    return () => {
      setUnits([]);
      clearInterval(interval);
      setUnassignedAssignments([]);
      // console.log("[Dashboard] Unmounting: removing units, assignments and clearing timer!");
    };
  }, [currentDate]);

  const handlePublishJob = useCallback(
    async (unitID, unpublishedCount) => {
      if (!unpublishedCount) {
        return;
      }
      await publishJobs(currentDate, unitID);
      await publishRoutes(currentDate, unitID);
      const updatedUnit = await API.Unit.fetchUnitWithAssignments(unitID, currentDate);

      // Only update the specific unit and not the other ones
      setUnits((prevUnits) => prevUnits.map((unit) => (unit.id === unitID ? updatedUnit : unit)));
    },
    [currentDate]
  );

  const handlePublishAllAssignments = useCallback(async () => {
    const unpublishedCount = unpublishedAssignmentsCount(units);

    if (!unpublishedCount) {
      return;
    }

    await publishJobs(currentDate, null);
    await publishRoutes(currentDate, null);

    const data = await API.Unit.fetchUnitsWithAssignments({ date: currentDate });
    const orderedUnits = orderBy(data, ["name", "asc"]);

    setUnits(orderedUnits);
  }, [units]);

  // select / unselect all units types if select all is clicked
  const handleSelectAllTypes = (event) => {
    setCheckedTypesAll([event.target.checked, event.target.checked]);

    if (selectedUnitsType.length === unitsTypesList.length) {
      setSelectedUnitsType([]);
    } else {
      const selectAllTypes = unitsTypesList.map((unit) => unit.label);
      setSelectedUnitsType(selectAllTypes);
    }
  };

  // select / unselect all units if select all is clicked
  const handleSelectAllUnits = (event) => {
    setCheckedAll([event.target.checked, event.target.checked]);

    if (selectedUnits.length === unitsList.length) {
      setSelectedUnits([]);
    } else {
      const selectAllUnits = unitsList.map((unit) => unit.value);
      setSelectedUnits(selectAllUnits);
    }
  };

  const handleDeleteJob = async () => {
    const { deleteJob, deleteRoute } = props;

    if (deleteType && deleteType === "route") {
      await deleteRoute(routeToDelete);
    } else {
      await deleteJob(jobToDelete);
    }

    setDeleteJobModalOpened(false);
    setJobToDelete(null);
    setRouteToDelete(null);

    fetchAssignments.run();
  };

  const handleChangeDate = useCallback(
    (date) => {
      setCurrentDate(formattedDate(date));
    },
    [currentDate]
  );

  // update rendering of units selected individually
  const handleSelectedUnitsChange = () => (event, child) => {
    const {
      target: { value },
    } = event;

    setSelectedUnits(value);

    if (child.props.value === "allUnits") {
      if (selectedUnits.length === unitsList.length) {
        setSelectedUnits([]);
      } else {
        const selectAll = unitsList.map((unit) => unit.value);
        setSelectedUnits(selectAll);
      }
    }

    value.length === unitsList.length ? setCheckedAll(true) : setCheckedAll(false);
  };

  // update rendering of units select
  const renderUnitsSelectValues = () => (event) => {
    if (!event) {
      return;
    }

    const value = event.filter((unit) => unit !== "allUnits");

    const nbOfUnits = unitsList.length;
    const selectedNumber = value.length;
    const unitsCounter = `(${selectedNumber}/${nbOfUnits})`;

    if (!nbOfUnits) {
      return <Trans i18nKey="unit_select.none_available" />;
    }

    if (!selectedNumber) {
      return (
        <span>
          <Trans i18nKey="unit_select.none_selected" /> {unitsCounter}
        </span>
      );
    }

    if (selectedNumber === nbOfUnits) {
      return (
        <span>
          <Trans i18nKey="unit_select.all_selected" /> ({selectedNumber})
        </span>
      );
    }

    if (selectedNumber === 1) {
      return (
        <span>
          <Trans i18nKey="unit_select.one_selected" /> {unitsCounter}
        </span>
      );
    }

    return (
      <span>
        <Trans i18nKey="unit_select.many_selected" /> {unitsCounter}
      </span>
    );
  };

  // update rendering of units types selected individually
  const handleSelectedUnitsTypeChange = () => (event, child) => {
    const {
      target: { value },
    } = event;
    setSelectedUnitsType(value);

    if (child.props.value === "allTypes") {
      if (selectedUnitsType.length === unitsTypesList.length) {
        setSelectedUnitsType([]);
      } else {
        const selectAllTypes = unitsTypesList.map((unit) => unit.label);
        setSelectedUnitsType(selectAllTypes);
      }
    }

    value.length === unitsTypesList.length ? setCheckedTypesAll(true) : setCheckedTypesAll(false);
  };

  // update rendering of units type select
  const renderUnitsTypeSelectValues = () => (event) => {
    if (!event) {
      return;
    }

    const value = event.filter((value) => value !== "allTypes");

    const nbOfUnitsType = unitsTypesList.length;
    const selectedNumber = value.length;
    const unitsCounter = `(${selectedNumber}/${nbOfUnitsType})`;

    if (!nbOfUnitsType) {
      return <Trans i18nKey="unit_select.none_available" />;
    }

    if (!selectedNumber) {
      return (
        <span>
          <Trans i18nKey="unit_select.none_selected" /> {unitsCounter}
        </span>
      );
    }

    if (selectedNumber === nbOfUnitsType) {
      return (
        <span>
          <Trans i18nKey="units.unit_type" /> ({selectedNumber})
        </span>
      );
    }

    if (selectedNumber === 1) {
      return (
        <span>
          <Trans i18nKey="unit_select.one_selected" /> {unitsCounter}
        </span>
      );
    }

    return (
      <span>
        <Trans i18nKey="unit_select.many_selected" /> {unitsCounter}
      </span>
    );
  };

  const handleToggleDeleteJobModal = (opened, jobId, deleteType) => () => {
    let jobToDelete;
    let routeToDelete;
    if (deleteType === "job") {
      jobToDelete = opened ? jobId : null;
    } else {
      routeToDelete = opened ? jobId : null;
    }

    setDeleteJobModalOpened(opened);
    setJobToDelete(jobToDelete);
    setRouteToDelete(routeToDelete);
  };

  const handleToggleAddJobModal = useCallback(
    (addJobModalOpened) => {
      setAddJobModalOpened(addJobModalOpened);
    },
    [addJobModalOpened]
  );

  const handleCloseUnitSelector = () => {
    setOpenUnitSelector(!openUnitSelector);
    setUnitAlreadyAssigned(null);
    setUnitId(-1);
  };

  const assignAnotherUnitToRoute = async () => {
    setShowSpinner(true);
    await createAnotherAssignmentToRoute({
      unit_to_assign: unitId,
      assignment_id: currentAssignmentId,
    });

    fetchAssignments.run();
    setShowSpinner(false);
    setOpenUnitSelector(false);
  };

  const handleToggleRemoveHelpOnUnit = () => {
    setOpenRemoveHelpOnUnit((prevState) => !prevState);
  };

  const removeUnitFromHelpOnUnit = async () => {
    const dailyAssignments = units.flatMap((unit) => unit.dailyAssignments);
    const type = dailyAssignments.find((assignment) => assignment.assignableId === currentAssignmentId)?.type;

    setShowSpinner(true);
    await API.Assignment.deleteAssignmentHelpOnUnit(unitId, currentAssignmentId, type);
    fetchAssignments.run();
    setShowSpinner(false);
    handleToggleRemoveHelpOnUnit();
  };

  const renderOpenUnitSelector = () => {
    const dailyAssignments = units.flatMap((unit) => unit.dailyAssignments);
    const type = dailyAssignments.find((assignment) => assignment.id === currentAssignmentId)?.type;
    const unitType = type === "route" ? "AVANT" : "LATERAL";

    const availableUnits = orderBy(units, ["name", "id"], ["asc", "asc"]).filter(
      (unit) => unitType === unit.unitLoadingType && unit.id !== unitAlreadyAssigned
    );

    if (openUnitSelector) {
      return (
        <DialogWrapper
          id="quick-job-modal"
          open={openUnitSelector}
        >
          {showSpinner && <LoaderSpinner text={<Trans i18nKey="jobs.job_creation" />} />}
          <DialogTitle>Sélectionner une autre unité à assignée à cette route</DialogTitle>

          <SelectHalfUi
            id="cpbr-unit"
            onChange={(event) => setUnitId(event.target.value)}
            value={`${unitId}`}
            style={{ position: "absolute", left: "50%" }}
          >
            {renderMenuItems(<Trans i18nKey="select_unit" />, availableUnits, "name")}
          </SelectHalfUi>

          <DialogActions>
            <Button onClick={() => handleCloseUnitSelector()}>
              <Trans i18nKey="cancel" />
            </Button>

            <Button
              disabled={unitId === "-1"}
              onClick={assignAnotherUnitToRoute}
              variant="contained"
            >
              <Trans i18nKey="add" />
            </Button>
          </DialogActions>
        </DialogWrapper>
      );
    }
  };

  const onDragStart = useCallback(() => {
    isDragging.current = true;
  }, []);

  const onDragEnd = useCallback(
    (result) => {
      const { draggableId, source, destination } = result;
      isDragging.current = false;
      // console.log("[onDragEnd] result:", result);

      if (!destination) {
        console.warn("[onDragEnd] Dragged outside the list!");
        return;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        console.warn("[onDragEnd] Dragged at the same position!");
        return;
      }

      if (destination.droppableId === "-1" && source.droppableId === "-1") {
        console.warn("[onDragEnd] Dragged from and to the unassigned cart!");
        return;
      }

      // Convenience variables
      const isUnassignedCart = source.droppableId === "-1" || destination.droppableId === "-1";

      // Reorder assignments
      const [updatedUnassignedAssignments, updatedUnits, assignmentsToUpdate] = reorderAssignments(
        isUnassignedCart ? unassignedAssignments : [],
        filteredUnits,
        source.index,
        source.droppableId,
        destination.index,
        destination.droppableId
      );

      // Since we send filteredUnits to reorderAssignments, just update the specific units of the units state
      setUnits((prevUnits) =>
        map(prevUnits, (unit) => {
          const updatedUnit = find(updatedUnits, { id: unit.id });
          return updatedUnit ? updatedUnit : unit;
        })
      );

      // Only update the unassigned assignments state if we drag-and-dropped an assignment from or to the unsassigned cart.
      isUnassignedCart && setUnassignedAssignments(updatedUnassignedAssignments);

      // Send the data to the backend to update the database with all the the changes to the assisgnments
      API.Unit.reassign({ assignments: assignmentsToUpdate });
    },
    [filteredUnits, unassignedAssignments]
  );

  const UnitsList = useCallback(() => {
    return (
      <Stack
        spacing={0}
        style={{ overflowX: "auto" }}
      >
        {map(filteredUnits, (unit, index) => {
          return (
            <UnitRow
              key={index}
              unit={unit}
              prefix={index}
              fetchDataStatus={fetchAssignments.loading}
              currentDate={currentDate}
              handlePublishJob={handlePublishJob}
              handleOpenUnitSelector={handleOpenUnitSelector}
              handleOpenRemoveHelpOnUnit={handleOpenRemoveHelpOnUnit}
            />
          );
        })}
      </Stack>
    );
  }, [filteredUnits, fetchAssignments.loading]);

  // unit / unit type dropdown style
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    autoFocus: false,
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const selectedUnitsList = unitsList.map(({ label, value }) => {
    return (
      <MenuItem
        key={label}
        value={value}
      >
        <Checkbox checked={selectedUnits.indexOf(value) > -1} />
        <ListItemText primary={label} />
      </MenuItem>
    );
  });

  const selectedUnitsTypeList = unitsTypesList.map(({ label, value }) => {
    return (
      <MenuItem
        key={label}
        value={value}
      >
        <Checkbox checked={selectedUnitsType.indexOf(value) > -1} />
        <ListItemText primary={label} />
      </MenuItem>
    );
  });

  const renderUnitSelector = () => {
    return (
      <div style={H1Style}>
        <Select
          multiple
          displayEmpty
          value={selectedUnits}
          onChange={handleSelectedUnitsChange()}
          renderValue={renderUnitsSelectValues()}
          MenuProps={MenuProps}
          autoWidth
          variant="filled"
          sx={{ backgroundColor: "#F8F8F8 !important" }}
        >
          <MenuItem value="allUnits">
            <Checkbox
              checked={checkedAll === true || selectedUnits.length === unitsList.length}
              onChange={handleSelectAllUnits}
              indeterminate={selectedUnits.length > 0 && selectedUnits.length < unitsList.length}
            />
            <ListItemText primary="Tout sélectionner" />
          </MenuItem>
          {selectedUnitsList}
        </Select>
      </div>
    );
  };

  const renderUnitTypeSelector = () => {
    return (
      <div style={H1Style}>
        <Select
          multiple
          displayEmpty
          value={selectedUnitsType}
          onChange={handleSelectedUnitsTypeChange()}
          renderValue={renderUnitsTypeSelectValues()}
          MenuProps={MenuProps}
          autoWidth
          variant="filled"
          sx={{ backgroundColor: "#F8F8F8 !important" }}
        >
          <MenuItem value="allTypes">
            <Checkbox
              checked={checkedTypesAll === true || selectedUnitsType.length === unitsTypesList.length}
              onChange={handleSelectAllTypes}
              indeterminate={selectedUnitsType.length > 0 && selectedUnitsType.length < unitsTypesList.length}
            />
            <ListItemText primary="Tout sélectionner" />
          </MenuItem>
          {selectedUnitsTypeList}
        </Select>
      </div>
    );
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <Wrapper>
        <DashboardContainer>
          <DashboardHeader>
            <Stack
              alignItems="flex-start"
              direction="row"
            >
              {renderUnitSelector()}
              {renderUnitTypeSelector()}
            </Stack>

            <ActionBar
              changeCurrentDate={handleChangeDate}
              currentDate={currentDate}
              openAddJobModal={() => handleToggleAddJobModal(true)}
              publishJobs={handlePublishAllAssignments}
              refreshJobs={fetchAssignments.run}
              unpublishedCount={unpublishedAssignmentsCount(units)}
            />
          </DashboardHeader>

          <DashboardContent>
            {/* The unassigned cart where we have all the unassigned assignments */}
            <UnassignedCart
              currentDate={currentDate}
              fetchDataStatus={fetchAssignments.loading}
              unassignedAssignments={unassignedAssignments}
            />

            {/* Linear loading bar to show we're fetching data */}
            {fetchAssignments.loading && <LinearProgressBar />}

            {/* The units rows with each of their assignments */}
            {UnitsList()}
          </DashboardContent>
        </DashboardContainer>

        {renderOpenUnitSelector()}
        <RemoveHelpOnUnit
          onClose={handleToggleRemoveHelpOnUnit}
          isOpen={openRemoveHelpOnUnit}
          isLoading={showSpinner}
          onRemove={removeUnitFromHelpOnUnit}
        />

        {isModalQuickTaskShowing && (
          <ModalQuickTask
            onClose={handleCloseModalQuickTask}
            isOpen={isModalQuickTaskShowing}
            units={unitsList}
          />
        )}

        <CtaButton
          onClick={setIsModalQuickTaskShowing.on}
          style={{ marginLeft: "15px", marginRight: "5px" }}
          color="secondary"
        >
          <Icon>add</Icon>
        </CtaButton>

        <ModalWarning
          onCancel={handleToggleDeleteJobModal(false)}
          onSubmit={handleDeleteJob}
          open={deleteJobModalOpened}
          title={<Trans i18nKey="warning" />}
        >
          <Trans i18nKey="warning_delete_job" />
        </ModalWarning>
      </Wrapper>
    </DragDropContext>
  );
}

export default withRouter(withAssignments(withRoutes(withJobs(AssignmentDashboardPage))));
