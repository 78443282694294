import { FormGroup } from "@mui/material";
import styled from "@emotion/styled";

const FormGroupWrapper = styled(FormGroup)`
  margin-top: 5px;
  margin-bottom: 25px;
  padding-top: 5px;
`;

export default FormGroupWrapper;
