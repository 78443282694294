import axios from "axios";

import { requestOptions } from "../auth";

const deleteAssignmentHelpOnUnit = async (unitId, assignableId, type) => {
  try {
    const response = await axios.delete(
      `${
        import.meta.env.VITE_API_URL
      }/assignments/remove_help_assignment_from_route.json?assignable_id=${assignableId}&unit_id=${unitId}&type=${type}`,
      requestOptions()
    );

    return response.data;
  } catch (error) {
    console.warn("[API.deleteAssignmentHelpOnUnit] Error:", error);
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `deleteAssignmentHelpOnUnit`,
 */
export { deleteAssignmentHelpOnUnit };
