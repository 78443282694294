import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Toolbar } from "@mui/material";
import { debounce } from "lodash";
import { PropTypes } from "prop-types";
import React, { PureComponent } from "react";
import { withRoutes } from "optigo-redux";
import moment from "moment";
import styled from "@emotion/styled";
import { withRouter } from "@utils/withRouter";

import ModalRoute from "./ModalRoute";
import ModalWarning from "./ModalWarning";
import FloatingActionButton from "./ui/FloatingActionButton";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TextFieldUi from "./ui/TextField";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import { formattedDate } from "@utils/dates";
import Tooltip from "./ui/Tooltip";
import * as API from "@services";
import { Trans } from "react-i18next";
import { CollectionPath } from "@components/routing/constants";
import deleteCollection from "@services/collections/delete-collection.service";

const headerLabels = {
  assigned: { label: <Trans i18nKey="routes.assigned" /> },
  assignedTo: { label: <Trans i18nKey="routes.assigned_to" /> },
  endDate: { label: <Trans i18nKey="end_date" /> },
  name: { label: <Trans i18nKey="routes.name" /> },
  noteComments: { label: <Trans i18nKey="routes.note_comments" /> },
  routeTemplateName: { label: <Trans i18nKey="routes.route_template" /> },
  startDate: { label: <Trans i18nKey="start_date" /> },
};

const RemoveIconWrapper = styled(RemoveIcon)`
  font-size: 12px !important;
  position: relative;
`;

class RoutesList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "startDate",
    direction: "desc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    startDate: formattedDate(),
    deleteRouteModalOpened: false,
    routeModalOpened: false,
    routeToEdit: {},
    routeToDelete: null,
    sort: {
      ...this.initialSort,
    },
    units: [],
  };

  state = {
    ...this.initialState,
  };

  componentDidMount() {
    this.fetchRoutes();
    this.fetchUnits();
  }

  componentWillUnmount() {
    this.props.flushRoutes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
      this.fetchRoutes();
      this.currentTab();
    }
  }

  currentTab = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const currentTab = queryParams.get("current_tab");
    if (currentTab) {
      this.setState({ currentTab: parseInt(currentTab) });
    }
  };

  debouncedFetchRoutes = debounce(() => {
    this.fetchRoutes();
  }, 300);

  fetchUnits = async () => {
    const units = await API.Unit.fetchUnits({ rowsPerPage: "all" });

    this.setState({ units: units?.instances ?? [] });
  };

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchRoutes
    );
  };

  fetchRoutes = () => {
    const { fetchCustomerItemRoutes, router } = this.props;
    const { filter } = this.state;

    fetchCustomerItemRoutes(router.params.customerItemId, {
      filter: filter.trim(),
      page: 1,
      limit: 5000,
    });
  };

  handleFilter = handleFilter(this.debouncedFetchRoutes).bind(this);
  handlePageChange = handlePageChange(this.fetchRoutes).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchRoutes).bind(this);
  handleSort = handleSort.bind(this);

  handleDeleteRoute = async () => {
    const { deleteRoute, customerItem } = this.props;
    const { contractType } = customerItem;
    if (contractType === "collection") {
      await deleteCollection(this.state.routeToDelete);
    } else {
      await deleteRoute(this.state.routeToDelete);
    }

    const { deleteRouteModalOpened, routeToDelete } = this.initialState;

    this.setState(
      {
        deleteRouteModalOpened,
        routeToDelete,
      },
      this.fetchRoutes
    );
  };

  handleRowClick = (path) => () => {
    console.log("path", path);
    this.props.router.navigate(path);
  };

  handleToggleDeleteRouteModal = (opened, routeId) => (e) => {
    e.stopPropagation();

    const routeToDelete = opened ? routeId : this.initialState.routeToDelete;
    this.setState({ deleteRouteModalOpened: opened, routeToDelete });
  };

  handleToggleRouteModal = (routeModalOpened) => () => {
    this.setState({
      routeModalOpened,
    });
  };

  renderCheckbox = (checked) =>
    checked ? (
      <CheckCircleIcon
        color="success"
        fontSize="small"
      />
    ) : (
      <RemoveIconWrapper fontSize="small" />
    );

  renderDeleteIcon = (assigned, endDate, id) => {
    if (endDate) {
      return <Tooltip />;
    }

    const label = assigned ? "warning_assigned_route" : "delete";

    return (
      <Tooltip title={<Trans i18nKey={label} />}>
        <span>
          <IconButton
            color="secondary"
            id="cpbr-delete-route"
            disabled={assigned}
            onClick={this.handleToggleDeleteRouteModal(true, id)}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;

    return (
      <TableHead>
        <TableRow>
          {["startDate", "endDate", "name", "routeTemplateName", "noteComments", "assignedTo", "assigned"].map(
            (name) => (
              <TableCell key={name}>
                <TableSortLabel
                  active={columnName === name}
                  direction={direction}
                  onClick={this.handleSort(name)}
                >
                  {headerLabels[name].label}
                </TableSortLabel>
              </TableCell>
            )
          )}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  getUnitName = (unitIds) => {
    const unitsNameToDisplay = this.state.units.filter((unit) => unitIds.includes(unit.id));
    return unitsNameToDisplay.reduce((result, item) => `${result}${item.name}, `, "");
  };

  renderTableRows = () => {
    const { routes, routesLoading, customerItem } = this.props;
    const { contractType } = customerItem;
    const { limit, page } = this.state;

    if (routesLoading) {
      return <TableLoading />;
    }

    if (!routes.length) {
      return <TableCellNoData />;
    }

    const bottomLimit = page * limit;
    const topLimit = (page + 1) * limit;

    return sortedData(routes, this.state)
      .slice(bottomLimit, topLimit)
      .map((route) => {
        const { router } = this.props;
        const { customerId, contractId, customerItemId } = router.params;

        if (contractType === "collection") {
          return (
            <TableRow
              className="link-row"
              key={route.id}
              onClick={this.handleRowClick(
                CollectionPath({
                  collectionId: route.id,
                  geoCollectionUUID: route.geoCollectionUuid,
                })
              )}
            >
              <TableCell>{formattedDate(route.startDate)}</TableCell>
              <TableCell />
              <TableCell>{route.masterRoute}</TableCell>
              <TableCell>{route.name}</TableCell>
              <TableCell />
              <TableCell>{route.unitNames ? route.unitNames.join(", ") : ""}</TableCell>
              <TableCell classes={{ root: "icon-cell" }}>{this.renderCheckbox(!!route.unitNames)}</TableCell>
              <TableCell classes={{ root: "action-cell no-pointer" }}>
                {this.renderDeleteIcon(!!route.unitNames, null, route.id)}
              </TableCell>
            </TableRow>
          );
        } else {
          return (
            <TableRow
              className="link-row"
              key={route.id}
              onClick={this.handleRowClick(
                `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/routes/${route.id}`
              )}
            >
              <TableCell>{route.startDate ? formattedDate(route.startDate) : ""}</TableCell>
              <TableCell>{route.endDate ? formattedDate(route.endDate) : ""}</TableCell>
              <TableCell>{route.routeTemplateMasterRoute}</TableCell>
              <TableCell>{route.name}</TableCell>
              <TableCell>{route.noteComments}</TableCell>
              <TableCell>{this.getUnitName(route.unitIds)}</TableCell>
              <TableCell classes={{ root: "icon-cell" }}>{this.renderCheckbox(route.assigned)}</TableCell>
              <TableCell classes={{ root: "action-cell no-pointer" }}>
                {this.renderDeleteIcon(route.assigned, route.endDate, route.id)}
              </TableCell>
              <TableCell />
            </TableRow>
          );
        }
      });
  };

  render() {
    const { customerItem, routesCount } = this.props;

    const { filter, limit, page, routeModalOpened, deleteRouteModalOpened } = this.state;

    return (
      <PageContainer>
        <PaperWrapper>
          <div>
            <FloatingActionButton
              color="secondary"
              onClick={this.handleToggleRouteModal(true)}
            >
              <AddIcon />
            </FloatingActionButton>
          </div>

          <TableOverflowWrapper>
            <Toolbar>
              <TextFieldUi
                id="cpbr-filtre"
                label={<Trans i18nKey="filter" />}
                onChange={this.handleFilter}
                type="search"
                value={filter}
              />
            </Toolbar>

            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>

          <TablePaginationWrapper
            component="div"
            count={routesCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />

          {routeModalOpened ? (
            <ModalRoute
              customerItem={customerItem}
              open={routeModalOpened}
              onClose={this.handleToggleRouteModal(false)}
              refreshList={this.fetchAndResetPagination}
            />
          ) : null}

          <ModalWarning
            onCancel={this.handleToggleDeleteRouteModal(false)}
            onSubmit={this.handleDeleteRoute}
            open={deleteRouteModalOpened}
            title={<Trans i18nKey="warning" />}
          >
            <Trans i18nKey="warning_delete_route" />
          </ModalWarning>
        </PaperWrapper>
      </PageContainer>
    );
  }
}

RoutesList.defaultProps = {
  routesLoading: true,
};

RoutesList.propTypes = {
  customerItem: PropTypes.object.isRequired,
  deleteRoute: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  fetchCustomerItemRoutes: PropTypes.func.isRequired,
  flushRoutes: PropTypes.func.isRequired,

  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  routesCount: PropTypes.number.isRequired,
  routesLoading: PropTypes.bool,
};

export default withRouter(withRoutes(RoutesList));
