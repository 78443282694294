import { LineaCollectionSector } from "@@types/collection-sectors/collection-sector.type";
import { CollectionSectorBaseResponse } from "@@types/collection-sectors/collection-sectors-response.type";

function mapResponseToCollectionSector(response: LineaCollectionSector): CollectionSectorBaseResponse {
  return {
    id: response.id,
    masterCollectionSector: response.masterCollectionSector,
    completeName: response.completeName,
    supplierLocationId: response.supplierLocationId,
  };
}

function mapResponsesToCollectionSectors(responses: LineaCollectionSector[]): CollectionSectorBaseResponse[] {
  return responses.map(mapResponseToCollectionSector);
}

export { mapResponseToCollectionSector, mapResponsesToCollectionSectors };
