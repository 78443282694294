import React, { ReactElement, useCallback, useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CollapsableListChild from "@ui/collapsable-list/CollapsableListChild";

export type CollapsableListProps = {
  id: number;
  masterRoute: string;
  name: string;
  children: Child[];
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number, masterRouteId: number) => void;
  onCreate: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, masterRouteId: number) => void;
  routeTemplateLocationsCount?: number;
  path?: string;
  templateType: string;
};

type Child = {
  id: number;
  masterRoute: string;
  name: string;
  masterRouteId: number;
  routeTemplateLocationsCount?: number;
  path?: string;
  geoCollectionSectorUuid?: string;
  customerId?: number;
  contractId?: number;
  customerItemId?: number;
};

const CollapsableListParent = ({
  id,
  masterRoute,
  name,
  children,
  onCreate,
  onDelete,
  routeTemplateLocationsCount,
  path,
  templateType,
}: CollapsableListProps): ReactElement => {
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const handleCollapseRow = useCallback(() => {
    setOpenCollapse((prevState) => !prevState);
  }, []);

  const handleGoToPath = (): void => {
    path && navigate(path);
  };

  return (
    <React.Fragment>
      <TableRow
        className="link-row"
        key={id}
        onClick={handleCollapseRow}
      >
        <TableCell>
          <IconButton
            size="large"
            color="secondary"
          >
            {openCollapse ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{masterRoute}</TableCell>
        <TableCell>{routeTemplateLocationsCount || 0}</TableCell>
        <TableCell>
          <IconButton
            disabled={templateType != "CCAV"}
            color="secondary"
            onClick={(event) => onCreate(event, id)}
            size="large"
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </TableCell>

        <TableCell>
          <IconButton
            color="secondary"
            onClick={handleGoToPath}
            size="large"
          >
            <OpenInNewIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {children
        .sort((a, b) => parseInt(a.name) - parseInt(b.name))
        ?.map((child) => {
          const childPath = child?.geoCollectionSectorUuid
            ? path
            : `/customers/${child?.customerId}/contracts/${child?.contractId}/preparations/${child.customerItemId}/route_template_locations?masterRouteId=${id}&routeTemplateIds=${child.id}`;

          return (
            openCollapse && (
              <CollapsableListChild
                id={child.id}
                name={child.name}
                masterRoute={child.masterRoute}
                count={child.routeTemplateLocationsCount}
                onDelete={(event) => onDelete(event, child.id, child.masterRouteId)}
                path={childPath}
                templateType={templateType}
              />
            )
          );
        })}
    </React.Fragment>
  );
};

export default CollapsableListParent;
