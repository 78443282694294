import { Paper } from "@mui/material";
import styled from "@emotion/styled";

const PaperWrapper = styled(Paper)`
  margin-bottom: 75px;
  margin-top: 30px;
  padding-top: 15px;
  position: relative;
`;

export default PaperWrapper;
