import { Tooltip, CardContent as CardContentMui, Box, DialogTitle, DialogActions } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PeopleAltIcon from "@mui/icons-material/People";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FeedbackIcon from "@mui/icons-material/Feedback";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import moment from "moment";
import Typography from "@mui/material/Typography";
import React, { Component } from "react";
import styled from "@emotion/styled";
import ProgressBar from "@ramonak/react-progress-bar";
// import Tooltip from "./ui/Tooltip";
import SimpleBadge from "./SimpleBadge";
import visa_logo from "@assets/images/stripe/visa_logo.png";
import Button from "@mui/material/Button";
import stripeLogoWhite from "@assets/images/stripe/stripeLogoWhite.png";
import { orderBy } from "lodash";
import DialogWrapper from "@ui/DialogWrapper";
import LoaderSpinner from "@components/LoaderSpinner";
import SelectHalfUi from "@ui/SelectHalf";
import { formattedDate, formattedDateDayMonthYear } from "@utils/dates";
import { Trans } from "react-i18next";

const Card = styled.div`
  && {
    position: relative;
    width: 100%;
    border-radius: 8px;

    ${({ published, status, theme }) => `
      background-color: ${
        status === "TODO" ? (published ? "lightblue" : "#f4f4f4") : theme.colors.components.taskStatus[status]
      };
      cursor: ${status === "TODO" ? "grab" : "pointer"};
    `}
    :hover, :focus {
      ${({ status }) => `${status === "TODO" ? "transform: rotate(-1deg)" : ""} ;`}
    }

    transition: transform 0.15s;
  }
`;

const CardContent = styled(CardContentMui)`
  &&:last-child {
    padding: 12px 18px;
    transition: all 0.2s;
    border-radius: 8px;

    :hover {
      background: #fff;
    }
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  svg {
    margin-right: 8px;
  }
`;

const NbMasterRouteTemplateToDispatch = styled.div`
  position: absolute;
  font-size: 9px;
  background: #d23c3c;
  top: -3px;
  right: -5px;
  padding: 3px;
  color: #fff;
  border-radius: 3px;
`;

const ButtonToAddUnitToRoute = styled.div`
  position: absolute;
  font-size: 9px;
  top: 15px;
  right: -7px;
  padding: 3px;
  color: #fff;
`;

const RefundedIcon = styled.div`
  position: absolute;
  font-size: 15px;
  top: -5px;
  right: 5px;
  font-weight: bold;
  padding: 3px;
  color: green;
`;

const MessageIcon = styled.div`
  position: absolute;
  top: -6px;
  right: -15px;
  padding: 3px;
  color: red;
`;

const ButtonToDisplayPaymentCredit = styled.div`
  position: absolute;
  font-size: 9px;
  top: -7px;
  right: 70px;
  padding: 3px;
  color: #fff;
`;

const Content = styled.span`
  font-size: 0.7rem;
`;

const htmlTooltip = {
  padding: 8,
  color: "#FFF",
};

const initialState = {
  isHovered: false,
};

class JobCardMini extends Component {
  state = {
    ...initialState,
  };
  truncateLabel = (str) => (str && str.length > 12 ? `${str.substring(0, 9)}...` : str);

  renderIcon = () => {
    const { status } = this.props.job;

    switch (status) {
      case "COMPLETED": {
        return <CheckCircleOutlineIcon />;
      }
      case "TODO": {
        return <AccountCircleIcon />;
      }
      case "IN_PROGRESS": {
        return <RadioButtonUncheckedIcon />;
      }
      case "INTERRUPTED": {
        return <ErrorIcon />;
      }
      default:
        return null;
    }
  };

  renderAssignAnotherUnit = () => {
    const { isHovered } = this.state;
    const { openUnitSelector, openRemoveHelpOnUnit, job, helpRequest, helpOnUnit, unitId } = this.props;

    const { type, id } = job;
    if ((type === "route" || type === "collection") && helpRequest) {
      return (
        <ButtonToAddUnitToRoute onClick={() => openRemoveHelpOnUnit(unitId, id)}>
          <Tooltip
            arrow
            disableInteractive
            placement={"left"}
            title={
              <React.Fragment>
                Retirer l'aide du camion
                {" : "}
                {helpOnUnit}
              </React.Fragment>
            }
          >
            <PeopleAltIcon sx={{ color: "black", "&:hover": { color: "red" } }} />
          </Tooltip>
        </ButtonToAddUnitToRoute>
      );
    }
    if (type === "route" || type === "collection") {
      return (
        <ButtonToAddUnitToRoute>
          <button
            style={{
              height: "15px",
              padding: 2.5,
              fontSize: 8,
              background: "#D23C3C",
              color: "#FFF",
              border: 0,
              borderRadius: 3,
            }}
            onClick={() => openUnitSelector(unitId)}
          >
            Aide
          </button>
        </ButtonToAddUnitToRoute>
      );
    }
  };

  render() {
    const { onClick, unitId, published } = this.props;
    const {
      areaName,
      address,
      addressSimple,
      assignmentPublished,
      containerKind,
      contractPaymentTypeCode,
      contractNo,
      customerItemName,
      customerName,
      createdAt,
      kind,
      locationName,
      name,
      nbRouteLocationsLifted,
      nbRouteLocationsToLift,
      noteComments,
      noteCommentsColor,
      nbRouteLocationsWithAnomalies,
      nbNonAutomaticAnomalies,
      nbRemainingRouteLocationsToLift,
      nbRouteTemplateOnMasterRouteNotDispatched,
      noteLocationToDisplay,
      paymentIntentStatus,
      routeTemplateCompleteName,
      scheduleJobDays,
      scheduleJobFrequency,
      startDate,
      status,
      supplierLocationName,
      jobCreatorInfo,
      type,
      purchaseOrderNoToDisplay,
      isRefunded,
      commentsCount,
      completeName,
      matter,
    } = this.props.job;

    let routeName = "";
    if (!routeTemplateCompleteName) {
      routeName = name;
    } else {
      routeName = routeTemplateCompleteName;
    }

    const jobCompletionPercentage = parseFloat(
      (((nbRouteLocationsLifted + nbRouteLocationsWithAnomalies) / nbRouteLocationsToLift) * 100).toFixed(1)
    );

    const jobType = kind || routeTemplateCompleteName;

    if (type === "route" || type === "additional") {
      return (
        <Tooltip
          arrow
          disableInteractive
          placement={"right"}
          title={
            <React.Fragment>
              <Typography
                variant="body1"
                gutterBottom
                style={htmlTooltip}
              >
                <b>
                  {type === "route" ? <Trans i18nKey="route" /> : <Trans i18nKey="task" />}
                  {" : "}
                  <Trans
                    i18nKey="jobs_scheduled_on"
                    values={{
                      date: formattedDateDayMonthYear(startDate),
                      kind: jobType,
                    }}
                  />
                </b>
                <br />
                <Trans i18nKey="container_kind" />
                {" : "}
                {containerKind}
                <br />
                <Trans i18nKey="contract" />
                {" : "}
                {contractNo}
                <br />
                <Trans i18nKey="client" />
                {" : "}
                {customerName}
                <br />
                <Trans i18nKey="works_start" /> {formattedDate(startDate)}
                <br />
                <Trans i18nKey="preparation.note_route" />
                {" : "}
                {noteComments}
                <br />
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={htmlTooltip}
              >
                <Trans i18nKey="routes.lifting_to_do_count" />
                {" : "}
                {nbRouteLocationsToLift}
                <br />
                <Trans i18nKey="routes.lifting_done_count" />
                {" : "}
                {nbRouteLocationsLifted}
                <br />
                <Trans i18nKey="routes.remaining_lifting_count" />
                {" : "}
                {nbRemainingRouteLocationsToLift}
                <br />
                <Trans i18nKey="routes.nb_route_locations_anomalied" />
                {" : "}
                {nbRouteLocationsWithAnomalies}
                <br />
                <Trans i18nKey="routes.nb_declared_anomalies" />
                {" : "}
                {nbNonAutomaticAnomalies}
                <br />
              </Typography>
            </React.Fragment>
          }
        >
          <Card
            status={status}
            published={published}
          >
            <CardContent
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
              sx={{ padding: "10px" }}
            >
              <MainContent>
                <Content>{locationName || routeName || addressSimple}</Content>
              </MainContent>
              {type === "route" && nbRouteTemplateOnMasterRouteNotDispatched > 0 ? (
                <NbMasterRouteTemplateToDispatch>
                  {nbRouteTemplateOnMasterRouteNotDispatched}
                </NbMasterRouteTemplateToDispatch>
              ) : (
                ""
              )}
            </CardContent>
            {unitId !== -1 && (type === "route" || type === "collection") && this.renderAssignAnotherUnit()}
            {nbRouteLocationsToLift === 0 ? (
              <SimpleBadge color="dark">Aucune levée assignée</SimpleBadge>
            ) : (
              <ProgressBar
                completed={jobCompletionPercentage}
                height="10px"
                bgColor="#006f9c"
                labelSize="10px"
                labelColor="#000"
                labelAlignment="outside"
              />
            )}
          </Card>
        </Tooltip>
      );
    }

    if (type === "collection") {
      return (
        <Tooltip
          arrow
          disableInteractive
          placement={"right"}
          title={
            <React.Fragment>
              <Typography
                variant="body1"
                gutterBottom
                style={htmlTooltip}
              >
                <b>
                  Collection
                  {" : "}
                  <Trans
                    i18nKey="jobs_scheduled_on"
                    values={{
                      date: formattedDateDayMonthYear(startDate),
                      kind: completeName,
                    }}
                  />
                </b>
                <br />
                <Trans i18nKey="container_kind" />
                {" : "}
                {matter}
                <br />
                <Trans i18nKey="contract" />
                {" : "}
                {contractNo}
                <br />
                <Trans i18nKey="client" />
                {" : "}
                {customerName}
                <br />
                <Trans i18nKey="works_start" /> {formattedDate(startDate)}
                <br />
                <Trans i18nKey="drop_point_1" />
                {" : "}
                {supplierLocationName}
                <br />
              </Typography>
            </React.Fragment>
          }
        >
          <Card
            status={status}
            published={published}
          >
            <CardContent
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
              sx={{ padding: "10px" }}
            >
              <MainContent>
                <Content>{completeName}</Content>
              </MainContent>
            </CardContent>
            {unitId !== -1 && type === "collection" && this.renderAssignAnotherUnit()}
          </Card>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        arrow
        disableInteractive
        placement={"right"}
        title={
          <Typography
            component="div"
            variant="body1"
            gutterBottom
            style={htmlTooltip}
          >
            <b>
              {type === "route" ? <Trans i18nKey="routes.route_template" /> : <Trans i18nKey="place" />}
              {" : "}
              {locationName || routeTemplateCompleteName}
            </b>
            <br />
            <b>
              {type === "route" ? <Trans i18nKey="route" /> : <Trans i18nKey="task" />}
              {" : "}
              <Trans
                i18nKey="jobs_scheduled_on"
                values={{
                  date: formattedDateDayMonthYear(startDate),
                  kind: jobType,
                }}
              />
            </b>
            <br />
            <Trans i18nKey="creation_date" />
            {" : "}
            {moment(createdAt).format("YYYY-MM-DD(HH:mm)")}
            <br />
            <Trans i18nKey="container_kind" />
            {" : "}
            {containerKind}
            <br />
            <Trans i18nKey="contract" />
            {" : "}
            {contractNo}
            <br />
            <Trans i18nKey="purchase_order_no_short" />
            {" : "}
            {purchaseOrderNoToDisplay}
            <br />
            <Trans i18nKey="customer_item_name" />
            {" : "}
            {customerItemName}
            <br />
            <Trans i18nKey="client" />
            {" : "}
            {customerName}
            <br />
            <Trans i18nKey="address.adr" />
            {" : "}
            {address} ({areaName})
            <br />
            <Trans i18nKey="drop_point_1" />
            {" : "}
            {supplierLocationName || ""}
            <br />
            <Trans i18nKey="preparation.note_comments" />
            {" : "}
            <span style={{ color: noteCommentsColor === "#000" ? "#FFF" : noteCommentsColor }}>{noteComments}</span>
            <br />
            <Trans i18nKey="preparation.note_location" />
            {" : "}
            {noteLocationToDisplay}
            <br />
            <Trans i18nKey="collect_day" />
            {" : "}
            {scheduleJobDays}
            <br />
            <Trans i18nKey="frequency" />
            {" : "}
            {scheduleJobFrequency}
            <br />
            <Trans i18nKey="created_by" />
            {" : "}
            {jobCreatorInfo}
          </Typography>
        }
      >
        <Card
          status={status}
          published={published}
          onClick={onClick}
        >
          {contractPaymentTypeCode == "CREDIT_CARD" && (
            <img
              // Show either the visa logo or the crossed one if the payment intent authorization failed
              src={visa_logo}
              alt="Logo Visa"
              height={20}
              style={{ position: "absolute", paddingBottom: "5px" }}
            />
          )}
          <CardContent>
            <MainContent>
              <Content>{this.truncateLabel(locationName || routeName || addressSimple)}</Content>
            </MainContent>
            {type === "route" && nbRouteTemplateOnMasterRouteNotDispatched > 0 ? (
              <NbMasterRouteTemplateToDispatch>
                {nbRouteTemplateOnMasterRouteNotDispatched}
              </NbMasterRouteTemplateToDispatch>
            ) : (
              ""
            )}
            {isRefunded && <RefundedIcon>R</RefundedIcon>}
            {commentsCount > 0 && (
              <MessageIcon>
                <FeedbackIcon fontSize={"small"} />
              </MessageIcon>
            )}
          </CardContent>
        </Card>
      </Tooltip>
    );
  }
}

export default JobCardMini;
