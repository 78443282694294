import { Customer, CustomerContract } from "@@types/customers/customer.type";
import useFetchPepCustomersList from "@hooks/customers/fetch-pep-customers-list.hook";
import { useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { LineaCollectionSector } from "@@types/collection-sectors/collection-sector.type";
import { Moment } from "moment/moment";
import moment from "moment";
import useNotifier from "@hooks/useNotifier";
import useCreateLineaCollection from "@hooks/collection-sectors/create-linea-collection.hook";
import { Supplier } from "@@types/suppliers/supplier.type";
import useFetchSuppliersList from "@hooks/suppliers/fetch-suppliers-list.hook";
import { formattedDate } from "@utils/dates";
import useFetchLineaCollectionSectorsByContract from "@hooks/collection-sectors/fetch-linea-collector-sectors-list.hook";

type UseQuickPepLayout = {
  customers: Customer[];
  selectedCollection: SelectedCollectionStates;
  contracts: CustomerContract[];
  collectionSectors: LineaCollectionSector[];
  supplierLocations: Supplier[];
  handleSubmitQuickPep: () => void;
  handleChangeSelectedValue: (event: SelectChangeEvent) => void;
  handleChangeStartDate: (event: Moment | null) => void;
};

export type SelectedCollectionState = {
  value: string;
  error: boolean;
};

export type SelectedCollectionStates = {
  customer: SelectedCollectionState;
  contract: SelectedCollectionState;
  collectionSector: SelectedCollectionState;
  unit: SelectedCollectionState;
  date: SelectedCollectionState;
  supplierLocation: SelectedCollectionState;
};

export type LineaCollectionPayload = {
  contractId: string;
  collectionSectorId: string;
  unitId: string;
  date: string;
  supplierLocationId: string;
  recurrence?: LineaCollectionRecurrence;
};

export type LineaCollectionRecurrence = {
  interval: string;
  type: string;
};

const selectedCollectionInitialState = {
  value: "",
  error: false,
};

const selectedCollectionInitialStates = {
  customer: selectedCollectionInitialState,
  contract: selectedCollectionInitialState,
  collectionSector: selectedCollectionInitialState,
  unit: selectedCollectionInitialState,
  date: { value: formattedDate(), error: false },
  supplierLocation: selectedCollectionInitialState,
};

const useQuickPepLayout = (onClose: () => void): UseQuickPepLayout => {
  const [selectedCollection, setSelectedCollection] = useState<SelectedCollectionStates>(
    selectedCollectionInitialStates
  );

  const { data: customers = [] } = useFetchPepCustomersList();
  const { data: collectionSectors = [] } = useFetchLineaCollectionSectorsByContract(selectedCollection.contract.value);
  const { data: supplierLocations = [] } = useFetchSuppliersList();
  const [contracts, setContracts] = useState<CustomerContract[]>([]);
  const { mutate: createCollection } = useCreateLineaCollection();
  const { enqueueMessage, enqueueMessages } = useNotifier();

  const handleSubmitQuickPep = (): void => {
    const isValid = valid();

    if (isValid) {
      const payload = {
        contractId: selectedCollection.contract.value,
        collectionSectorId: selectedCollection.collectionSector.value,
        unitId: selectedCollection.unit.value,
        supplierLocationId: selectedCollection.supplierLocation.value,
        date: selectedCollection.date.value,
      };

      createCollection(payload)
        .then(() => {
          enqueueMessage("La collecte a été créée avec succès.", "success");
          onClose();
        })
        .catch((res) => {
          enqueueMessages(res.response.data.messages, "error");
        });
    } else {
      enqueueMessage("La sélection du client est obligatoire. Veuillez réessayer.", "error");
    }
  };

  const handleChangeSelectedValue = (event: SelectChangeEvent): void => {
    setSelectedCollection((prevState) => ({
      ...prevState,
      [event.target.name]: { value: event.target.value, error: false },
    }));

    if (event.target.name == "customer") {
      const customer = customers.find((customer) => customer.id.toString() === event.target.value);
      if (customer) {
        setSelectedCollection((prevState) => ({
          ...prevState,
          contract: selectedCollectionInitialState,
          collectionSector: selectedCollectionInitialState,
        }));

        setContracts(customer.contracts);
      }
    }

    if (event.target.name == "contract") {
      setSelectedCollection((prevState) => ({
        ...prevState,
        collectionSector: selectedCollectionInitialState,
      }));
    }

    if (event.target.name == "collectionSector") {
      const collectionSector = collectionSectors.find(
        (collectionSector) => collectionSector.id.toString() === event.target.value
      );
      if (collectionSector) {
        setSelectedCollection((prevState) => ({
          ...prevState,
          supplierLocation: { value: collectionSector.supplierLocationId.toString(), error: false },
        }));
      }
    }
  };

  const handleChangeStartDate = (event: Moment | null): void => {
    setSelectedCollection((prevState) => ({
      ...prevState,
      date: { value: moment(event).format("YYYY-MM-DD"), error: false },
    }));
  };

  const valid = (): boolean => {
    let isValid = true;

    if (selectedCollection.customer.value === "") {
      setSelectedCollection((prevState) => ({
        ...prevState,
        customer: { ...prevState.customer, error: true },
      }));
      isValid = false;
    }

    return isValid;
  };

  return {
    customers,
    contracts,
    collectionSectors,
    selectedCollection,
    supplierLocations,
    handleSubmitQuickPep,
    handleChangeSelectedValue,
    handleChangeStartDate,
  };
};

export default useQuickPepLayout;
